const startHour = 9;
const endHour = 18;
const timeOptions = [];

for (let hour = startHour; hour <= endHour; hour++) {
  for (let minute = 0; minute <= 30; minute += 30) {
    if (hour === startHour && minute === 0) continue; // Skip 9:00
    if (hour === endHour) continue; // Skip 17:30
    const timeString = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
    timeOptions.push(timeString);
  }
}

export default timeOptions;
