import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import whatsapp from './whatsapp.svg';
import call from './call.svg';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    zIndex: 9,
    right: 24,
    bottom: 24,
  },
  image: {
    width: 55,
    height: 55,
    filter:
      'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.12)), drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08)), drop-shadow(0px 9px 28px rgba(0, 0, 0, 0.05))',
    marginTop: '14px',
  },
}));

const FloatingIcons = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <a href="tel:852 2163 7644" title="Call">
        <Image className={classes.image} src={call} alt="call" lazy={false} />
      </a>
    </div>
  );
};

export default FloatingIcons;
